<template>
  <Dialog style="width: 997px; height: 749px" v-model:visible="show" :header="form.id ? '编辑问题':'发布问题'"
          @hide="closeDialog">
    <div class="p-col-12">
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-4">
          <div>
            <label>{{typeState==2?'稿件名称':'问题名称'}}:</label>
            <InputText @blur="change" v-model="form.title" class="p-mt-2" placeholder="名称"/>
            <span class="change">{{ mas }}</span>
          </div>
        </div>
        <div class="p-col-12 p-md-4">
          <div>
            <label>所属行业:</label>
            <MultiSelect @blur="change3" class="p-mt-2" v-model="industryList" :options="industry" optionLabel="name"
                         optionValue="name" placeholder="选择所属行业"/>
            <span class="change">{{ mas3 }}</span>
          </div>
        </div>
        <div class="p-col-12 p-md-4">
          <div>
            <label>关联诉求:</label>
            <Dropdown class="p-mt-2" v-model="form.demandsId" :options="List" optionLabel="title" option-value="id"/>
            <!--                <span class="change">{{mas4}}</span>-->
          </div>
        </div>
        <div class="p-col-12 p-md-6">
          <div>
            <label>简介:</label>
            <InputText @blur="change1" v-model="form.brief" class="p-mt-2"/>
            <span class="change">{{ mas1 }}</span>
          </div>
        </div>

        <div class="p-col-12 p-md-6">
          <div>
            <label>报酬:</label>
            <InputNumber @blur="change2" class="p-mt-2" v-model="form.gainful" mode="currency" currency="CNY"
                         currencyDisplay="code" locale="zh-CN"/>
            <span class="change">{{ mas2 }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <div>
        <label>内容：</label>
        <div class="p-mt-2">
          <my-editor :value="form.content" v-on:input="setContent"></my-editor>
<!--          <Editor v-model="form.content" editorStyle="height: 320px"/>-->
        </div>
      </div>
    </div>
    <div style="padding:20px 10%;display: flex;justify-content: space-between">
      <Button @click="close" label="取消发布" class="p-mr-2" icon="pi pi-fw pi-replay"/>
      <Button label="确认发布" @click="submit" class="p-mr-2" icon="pi pi-fw pi-send"/>
    </div>
    <Toast/>
  </Dialog>
</template>

<script>
import myEditor from "@/views/backend/myEditor";
export default {
  components:{myEditor},
  name: "",
  data() {
    return {
      mas: null,
      mas1: null,
      mas2: null,
      mas3: null,
      flag: false,
      flag1: false,
      show: false,

      typeState:1,
      industryList:[],
      form: {
        id: null,
        title: null,
        industry: null,
        content: null,
        brief: null,
        gainful: null,
        demandsId: null,
        tableType:1
      },
      industry: [],
      List: [],
    }
  },
  mounted() {
    this.$http.get('/dictionary/selectValue', {params: {key: 'suoshuhangye'}}).then((res) => {
      let list = res.data.myValue.split(",");
      /*console.log(res.data.myValue)*/
      let resList = []
      for (let i in list) {
        let item = {}
        item.name = list[i]
        resList.push(item)
      }
      /*console.log(resList)*/
      this.industry = resList;
    })
    this.getData()
  },
  methods: {
    setContent(val){this.form.content=val},
    init(typeState,data) {
      this.typeState = typeState
      this.form.tableType = typeState
      let a =data
      if (data.id) {
        this.form = a
        let industryList  = a.industry
        if(industryList!=null&&industryList!=''){
          this.industryList=industryList.split(',')
        }
        console.log(this.form.industry)
      }
      this.show = true
    },
    getData() {
      this.$http.get('/demands/list').then((res) => {
        for(let i = 0; i<res.data.length;i++){
          res.data[i].title = res.data[i].title+'('+res.data[i].companiesName+')'
        }
        this.List = res.data

        console.log(res.data)
      })
    },
    closeDialog() {
      this.show=false
      this.$emit('close', true)
    },
    change(e) {
      let b = e.target.value.replace(/^ +| +$/g, '')
      if (b != e.target.value) {
        this.mas = "您输入格式有误，请重新输入"
      } else {
        this.mas = null;
      }
    },
    change1() {
      this.mas1 = null;
    },
    change2() {
      this.mas2 = null;
    },
    change3() {
      this.mas3 = null;
    },
    change4() {
      this.mas4 = null;
    },
    close() {
      this.show = false;
      this.mas = null;
      this.mas1 = null;
      this.mas2 = null;
      this.mas3 = null;
      this.mas4 = null;
    },
    goto() {
      this.$router.push('/activityConfirmation')
    },
    submit() {

      let list = this.industryList
      let str = ''
      for (let i = 0; i < list.length; i++) {
        if (i < list.length-1) {
          str = str + list[i] + ','
        } else {
          str = str + list[i]
        }
      }

      this.form.industry = str
      let ths = this;
      (this.form.title == null | this.form.title == "") ? this.mas = "必填项，请输入" : this.mas = null;
      (this.form.brief == null | this.form.brief == "") ? this.mas1 = "必填项，请输入" : this.mas1 = null;
      (this.form.gainful == null | this.form.gainful == "") ? this.mas2 = "必填项，请输入" : this.mas2 = null;
      (this.form.industry == null | this.form.industry == "") ? this.mas3 = "必填项，请选择" : this.mas3 = null;
      /*(this.form.demandsId == null | this.form.demandsId == "") ? this.mas4="必填项，请选择": this.mas4 = null;*/
      if (this.form.title != null & this.form.title != ""
          & this.form.brief != null & this.form.brief != ""
          & this.form.gainful != null & this.form.gainful != ""
          & this.form.industry != null & this.form.industry != "") {

        this.$http.post('/question/saveOrUpdate', this.form).then(() => {
          this.$toast.add(
              {severity: 'success', summary: '成功', life: 3000}
          )
          ths.show = false
          ths.getData()
        })
      }
    }
  }
}
</script>

<style scoped>
.change {
  color: red;
}

</style>
